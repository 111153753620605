<template>
	<div class="product-list">
		<b-container>
			<h1 class="page-title-simple">
				<span>Wellvin Produtos</span>
			</h1>
			<!-- <div class="box-link">
				<a href="./assets/Wellvin-Catálogo-Páginas-WEB.pdf" target="_blank">Clique aqui para baixar o nosso catálogo</a>
			</div> -->
			<base-btn-sidebar />
			<div class="wrapper">
				<base-sidebar class="d-none d-md-block" />
				<div class="products">
					<b-row>
						<b-col class="col-item" v-for="item in filterRows" :key="item.id" cols="6" sm="4">
							<b-card class="card-item">
								<b-link href="" :to="{ name: 'product.detail', params: { id: item.id } }">
									<b-img v-if="item.images.length" :src="item.images[0]" fluid :alt="item.name" loading="lazy"></b-img>
									<h4 class="title">{{ item.name }}</h4>
									<base-variants :item="item" />
								</b-link>
								<div class="actions">
									<b-form-input type="number" v-model="item.qty" class="mr-2" />
									<b-btn variant="info" class="btn-add" @click="addToCart(item)" :disabled="disableButton(item)">
										adicionar
									</b-btn>
									<!-- <b-btn :to="{ name: 'product.detail', params: { id: item.id } }" variant="primary">Saiba mais</b-btn> -->
								</div>
							</b-card>
						</b-col>
					</b-row>
				</div>
			</div>
		</b-container>
	</div>
</template>

<script>
import BaseSidebar from '@/components/BaseSidebar.vue'
import BaseBtnSidebar from '../components/BaseBtnSidebar.vue'
import ProductService from '@/services/ProductService'
import BaseVariants from '@/components/BaseVariants.vue'
export default {
	components: { BaseSidebar, BaseBtnSidebar, BaseVariants },
	data: () => ({
		rows: []
	}),
	mounted() {
		this.init()
	},
	computed: {
		filterRows() {
			return this.rows.map(item => {
				item.qty = item.qty || 1

				return item
			})
		}
	},
	methods: {
		async init() {
			const data = await ProductService.get({
				category_id: this.$route.query.cat
			})
			this.rows = data.data
			setTimeout(() => {
				window.scroll({
					behavior: 'smooth',
					left: 0,
					top: 600
				})
			}, 250)
		},

		addToCart(item) {
			this.$store.dispatch('addToCart', item)
			this.$bvToast.toast('Produto adicionado com sucesso', {
				title: 'Orçamento',
				solid: true,
				variant: 'info'
			})
			setTimeout(() => {
				item.qty = 1
			}, 100)
		},
		disableButton(item) {
			return Boolean(item.tags.length && !item.variant)
		}
	},
	watch: {
		'$route.query.cat': {
			handler() {
				this.init()
			}
		}
	}
}
</script>
<style lang="scss">
.product-list {
	.box-link {
		text-align: center;
		margin-top: -4rem;
		margin-bottom: 4rem;
		a {
			line-height: 1;
			font-size: 1.8rem;
		}
	}
	.product-list-content {
		padding: 12rem 0;
	}

	.col-item:nth-child(3n + 1) {
		.card-item {
			border-left: none;
		}
	}

	.card-item {
		border: none;
		text-align: center;
		margin-bottom: 3rem;
		border-radius: 0;
		border-left: solid 1px #f2f2f2;

		a {
			color: $body-color;
		}

		img {
			max-width: 100%;
			padding: 20px;
			max-height: 26rem;
		}

		.title {
			font-weight: 600;
			margin-bottom: 0;
			margin-top: 1rem;
			min-height: 4rem;
		}
	}

	.actions {
		margin-top: 1rem;
		display: flex;
		justify-content: center;

		.btn-add {
			margin-right: 0.5rem;
		}
	}

	.wrapper {
		display: flex;
	}

	.products {
		flex-grow: 1;
	}
}
</style>
